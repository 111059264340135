import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './index.scss';
import Application from './Application';

import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(
    <BrowserRouter>
        <Application />
    </BrowserRouter>,
    document.getElementById('root')
);
