import * as React from 'react';
import { Switch, Route, RouteProps } from 'react-router-dom';
import About from './About';
import Catalog from './catalog/Catalog';
import Contacts from './Contacts';
import Manufactures from './manufacture/Manufactures';
import Promoutions from './Promoutions';
import Supply from './Supply';
import { ISearch } from '../models';
import Admin from './admin/Admin';

export default class Router extends React.Component<ISearch, {}> {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Switch>
                <Route exact path="/" component={About} />
                <Route path="/manufactures" component={Manufactures} />
                <Route path="/catalog" render={props => <Catalog {...props} searchText={this.props.searchText} />} />
                <Route path="/promoutions" component={Promoutions} />
                <Route path="/supply" component={Supply} />
                <Route path="/contacts" component={Contacts} />
                <Route path="/admin" component={Admin} />
            </Switch>
        );
    }
}
