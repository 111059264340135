import * as React from 'react';

class Instruction extends React.PureComponent {
    render() {
        return (
            <div className="introduction">
                <h3>Для загрузки каталога товаров к файлу предьявляются следующие требования:</h3>
                <ol>
                    <li>
                        Файл должен содержать следующие поля в указанном порядке:
                        <ul>
                            <li>number</li>
                            <li>name</li>
                            <li>manufacture</li>
                            <li>unit</li>
                            <li>quantity</li>
                            <li>price</li>
                        </ul>
                        <i>
                            Где:
                            <ul>
                                <li>number - № п/п</li>
                                <li>name - Наименование товара</li>
                                <li>manufacture - Артикул производителя </li>
                                <li>unit - Ед. изм.</li>
                                <li>Quantity - Кол-во на складе </li>
                                <li>price - Цена с НДС (руб) за 1 ед.</li>
                            </ul>
                        </i>
                    </li>
                    <li>
                        К полям предьявляются следющие требования
                        <div>name - должно быть всегда заполненным (не должно быть пустым)</div>
                        <div>price – допустимый разделитель точка или запятая</div>
                        <i>например 22 или 22,2 – корректно, “22 222” или “Договорная” - некорректно.</i>
                        <div>Остальные поля могут заполняться или нет.</div>
                    </li>
                </ol>
                <h3>Порядок действий:</h3>
                <ul>
                    <li>Подготовить файл</li>
                    <li>Выбрать файл для загрузки с помощью “Выберите файл для загрузки”</li>
                    <li>Нажать на кнопку “Отправить”</li>
                    <li>
                        При успехе выведится сообщение об этом. Если файл содержит ошибки, то они выведутся. В этом случае нужно исправить
                        ошибки, сохранить файл и повторить пункты 1 – 3
                    </li>
                </ul>
            </div>
        );
    }
}

export default Instruction;
