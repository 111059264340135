import * as React from 'react';
import '../../styles/pages/admin.scss';

type Props = {
    file: File | undefined;
};

class FileView extends React.Component<Props> {
    render() {
        const { file } = this.props;

        if (!file) {
            return null;
        }

        return (
            <div className="file-view">
                <b>Имя файла:</b> {file.name}; <b>размер:</b> {file.size} bytes
            </div>
        );
    }
}

export default FileView;
