import * as React from 'react';
import '../styles/pages/supply.scss';
import Card from './Card';

class Supply extends React.Component {
    render() {
        return (
            <div className="supply page-background">
                <div className="container">
                    <h1 className="text-title-mobile">Галерея Поставок</h1>
                </div>
                <div className="container">
                    <div className="container-style">
                        <span className="text_dark text-17 text-15-mobile text_normal">
                            <span className="text_green">1.</span> Производство модульных зданий по техническому заданию заказчика
                        </span>
                        <div className="img-items">
                            <Card
                                children={{
                                    imgSmall: '/supply/1.png',
                                    imgLarge: '/supply/1.png'
                                }}
                            />
                            <Card
                                children={{
                                    imgSmall: '/supply/2.png',
                                    imgLarge: '/supply/2.png'
                                }}
                            />
                            <Card
                                children={{
                                    imgSmall: '/supply/3.png',
                                    imgLarge: '/supply/3.png'
                                }}
                            />
                            <Card
                                children={{
                                    imgSmall: '/supply/2.png',
                                    imgLarge: '/supply/2.png'
                                }}
                            />
                            <Card
                                children={{
                                    imgSmall: '/supply/3.png',
                                    imgLarge: '/supply/3.png'
                                }}
                            />
                            <Card
                                children={{
                                    imgSmall: '/supply/1.png',
                                    imgLarge: '/supply/1.png'
                                }}
                            />
                        </div>
                    </div>
                    <div className="container-style">
                        <span className="text_dark text-17 text-15-mobile text_normal">
                            <span className="text_green">2.</span> Щитовое производство по техническому заданию заказчика
                        </span>
                        <div className="img-items">
                            <Card
                                children={{
                                    imgSmall: '/supply/1.png',
                                    imgLarge: '/supply/1.png'
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Supply;
