import * as React from 'react';
import '../styles/pages/supply.scss';

interface ICardState {
    imgActiveClass: string;
    classHiddenSmall: string;
    classHiddenLarge: string;
}

class Card extends React.Component<any, ICardState> {
    preloaderSmall;
    preloaderLarge;

    images = {
        small: '',
        large: '',
        alt: 'photo'
    };

    preloaderHtml = (
        <div className="img-card__preloader">
            <svg className="svg" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
                <circle strokeWidth="10" strokeMiterlimit="10" className="cls-1 path" cx="100" cy="100" r="94" />
            </svg>
        </div>
    );

    constructor(props) {
        super(props);

        this.images.small = props.children.imgSmall || '';
        this.images.large = props.children.imgLarge || '';
        this.images.alt = props.children.alt || '';
        this.state = {
            imgActiveClass: '',
            classHiddenSmall: 'img-hidden',
            classHiddenLarge: 'img-hidden'
        };
        this.preloaderSmall = this.preloaderHtml;
        this.preloaderLarge = this.preloaderHtml;
    }

    loadImageSmall() {
        this.setState({ classHiddenSmall: '' });
        this.preloaderSmall = null;
    }

    loadImageLarge() {
        this.setState({ classHiddenLarge: '' });
        this.preloaderLarge = null;
    }

    clickImg() {
        this.setState({
            imgActiveClass: !this.state.imgActiveClass ? 'img-card__large' : ''
        });
    }

    render() {
        return (
            <div className="img-card" onClick={this.clickImg}>
                <div className="img-card__small">
                    {this.preloaderSmall}
                    <img
                        className={this.state.classHiddenSmall}
                        onLoad={this.loadImageSmall}
                        src={this.images.small}
                        alt={this.images.alt}
                    />
                </div>
                <div className={this.state.imgActiveClass}>
                    {this.preloaderLarge}
                    <img
                        className={this.state.classHiddenLarge}
                        onLoad={this.loadImageLarge}
                        src={this.images.large}
                        alt={this.images.alt}
                    />
                </div>
            </div>
        );
    }
}

export default Card;
