import * as React from 'react';
import { Link } from 'react-router-dom';
import Navigaion from './Navigation';
import Search from './Search';
import '../styles/common/header.scss';
import { ISearchProp } from '../models';

class Header extends React.Component<ISearchProp, {}> {
    render() {
        return (
            <header className="header">
                <div className="header__top">
                    <div className="container">
                        <Link to="/" className="logo">
                            <img src="/logo.svg" alt="Электроватт" className="logo__img" />
                        </Link>
                        <div className="title-container">
                            <div className="title text-20 text_dark text_medium">Комплексные поставки электротехнического оборудования</div>
                            <ul className="mobile-contacts">
                                <li className="contacts__item contacts__item_phone text-15 text_dark text_medium">
                                    <a className="contacts__link" href="tel:+78123631278">
                                        +7 (812) 363-12-78
                                    </a>
                                </li>
                                <li className="contacts__item contacts__item_location text-15 text_dark text_medium">
                                    <a className="contacts__link" target="_blank" href="https://goo.gl/maps/FkUBsow6DtT2">
                                        г. Санкт-Петербург
                                    </a>
                                </li>
                            </ul>
                            <div className="desktop-search">
                                <Search onChangeSearchText={this.props.onChangeSearchText} searchText={this.props.searchText} />
                            </div>
                        </div>
                        <ul className="contacts">
                            <li className="contacts__item text-13 text_normal text_gray">
                                с <span className="text_dark">09:00</span> до <span className="text_dark">18:00</span> по будням
                            </li>
                            <li className="contacts__item contacts__item_phone text-15 text_dark text_medium">
                                <a className="contacts__link" href="tel:+78123631278">
                                    +7 (812) 363-12-78
                                </a>
                            </li>
                            <li className="contacts__item contacts__item_phone text-15 text_dark text_medium">
                                <a className="contacts__link" href="tel:+78129340937">
                                    +7 (921) 395-94-04
                                </a>
                            </li>
                            <li className="contacts__item contacts__item_location text-15 text_dark text_medium">
                                <a className="contacts__link" target="_blank" href="https://goo.gl/maps/FkUBsow6DtT2">
                                    г. Санкт-Петербург
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="header__bottom">
                    <div className="container">
                        <Navigaion />
                    </div>
                </div>
                <div className="mobile-search container">
                    <Search onChangeSearchText={this.props.onChangeSearchText} searchText={this.props.searchText} />
                </div>
            </header>
        );
    }
}

export default Header;
