import * as React from 'react';
import '../styles/pages/promoutions.scss';

class Promoutions extends React.Component {
    render() {
        return (
            <div className="promotions page-background">
                <div className="container">
                    <h1 className="text-title-mobile">Акции</h1>
                </div>
                <div className="container promotions__container">
                    <ul className="container-style catalog-list">
                        <li className="catalog-list__header text_gray text-13">
                            <span className="catalog-list__col1">Наименование</span>
                            <span className="catalog-list__col2">Завод-изготовитель</span>
                            <span className="catalog-list__col3">Кол-во</span>
                            <span className="catalog-list__col4">Цена за 1 ед. с НДС (самовывоз)</span>
                        </li>

                        <li className="catalog-list__main">
                            <ul className="catalog-list__products">
                                <li className="catalog-list__product text_dark text-15 text-13-mobile">
                                    <span className="catalog-list__col1 text_medium text-15-mobile">
                                        Сальник ввода-вывода 32мм диаметр кабеля 8-26мм (as-32)
                                    </span>
                                    <span className="catalog-list__col2">
                                        <span className="catalog-list__mobile text_gray">Завод-изготовитель:</span> EKF
                                    </span>
                                    <span className="catalog-list__col3">
                                        1 000 <span className="text_gray">шт.</span>
                                    </span>
                                    <span className="catalog-list__col4 catalog-list__promoutions">
                                        <span className="catalog-list__mobile text_gray catalog-list__price_mobile">
                                            Цена за 1 ед. с НДС (самовывоз):{' '}
                                        </span>
                                        <span className="catalog-list__price">
                                            10 <span className="text_gray">₽</span>
                                        </span>
                                    </span>
                                </li>
                                <li className="catalog-list__product text_dark text-15 text-13-mobile">
                                    <span className="catalog-list__col1 text_medium text-15-mobile">
                                        ПРИМА Розетка 4 поста наружная с заземлением 250В 16А со шторками белая монтажная пластина
                                        (RA16-411M-B) - потертости незначительные
                                    </span>
                                    <span className="catalog-list__col2">
                                        <span className="catalog-list__mobile text_gray">Завод-изготовитель:</span> TE Connectivity (Tyco)
                                    </span>
                                    <span className="catalog-list__col3">
                                        1 000 <span className="text_gray">шт.</span>
                                    </span>
                                    <span className="catalog-list__col4 catalog-list__promoutions">
                                        <span className="catalog-list__mobile text_gray catalog-list__price_mobile">
                                            Цена за 1 ед. с НДС (самовывоз):{' '}
                                        </span>
                                        <span className="catalog-list__price">
                                            1000 <span className="text_gray">₽</span>
                                        </span>
                                    </span>
                                </li>
                            </ul>
                        </li>

                        <li className="catalog-list__footer">
                            <span className="pagination">
                                <button className="pagination__button pagination__button_left" />
                                <button className="pagination__button pagination__button_active">1</button>
                                <button className="pagination__button">2</button>
                                <button className="pagination__button">...</button>
                                <button className="pagination__button">10</button>
                                <button className="pagination__button">11</button>
                                <button className="pagination__button pagination__button_right" />
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default Promoutions;
