import * as React from 'react';

import '../../styles/pages/admin.scss';

type Props = {
    onClick: () => void;
    visible: boolean;
};

class SendButton extends React.Component<Props> {
    static defaultProps = {
        visible: false
    };

    handleSend = () => {
        const { onClick, visible } = this.props;

        if (visible) {
            onClick();
        }
    };

    render() {
        const { visible } = this.props;

        if (!visible) {
            return null;
        }

        return (
            <div className="button">
                <button onClick={this.handleSend}>Отправить</button>
            </div>
        );
    }
}

export default SendButton;
