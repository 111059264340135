import * as React from 'react';

import '../../styles/pages/admin.scss';

export type ErrorItem = {
    name?: string;
    price?: string;
    lineNumber: string;
};

type Props = {
    errors: null | ErrorItem[];
};

class ErrorView extends React.Component<Props> {
    static defaultProps = {
        errors: null
    };

    render() {
        const { errors } = this.props;

        if (!errors) {
            return null;
        }

        return (
            <div className="errors">
                {errors.map((error, index) => (
                    <div className="error" key={index}>
                        <span>Номер строки: {error.lineNumber}</span>
                        {error.name && <span>Наименование: "{error.name}"</span>}
                        {error.price && <span>Цена: "{error.price}"</span>}
                    </div>
                ))}
            </div>
        );
    }
}

export default ErrorView;
