import * as React from 'react';
import '../styles/common/preloader.scss';

interface IPreloaderState {
    load: boolean;
}

class Preloader extends React.Component<any, IPreloaderState> {
    constructor(props) {
        super(props);
        this.state = {
            load: false
        };
        window.addEventListener('load', this.onLoadPage);
    }

    onLoadPage = () => {
        this.setState({ load: true });
    };

    preloaderHTML() {
        if (!this.state.load) {
            return (
                <div className="preloader">
                    <div className="preloader__icon">
                        <div className="preloader__circle" />
                    </div>
                </div>
            );
        } else {
            return '';
        }
    }

    render() {
        return this.preloaderHTML();
    }
}

export default Preloader;
