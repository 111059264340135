import * as React from 'react';
import Header from './pages/Header';
import Router from './pages/Router';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Preloader from './pages/Preloader';
import * as queryString from 'query-string';
import { ISearch } from './models';

class Application extends React.Component<RouteComponentProps, ISearch> {
    constructor(public props: RouteComponentProps) {
        super(props);
        this.onChangeSearchText = this.onChangeSearchText.bind(this);
        this.registerHistoryListen = this.registerHistoryListen.bind(this);
        this.registerHistoryListen({
            history: props.history
        });

        let searchText: string = '';
        if (props.location.pathname === '/catalog') {
            const params = queryString.parse(props.location.search);
            searchText = params.query ? params.query.toString() : '';
        }
        this.state = {
            searchText
        };
    }

    registerHistoryListen({ history }) {
        history.listen((location, action) => {
            const params = queryString.parse(location.search);
            let searchText: string = '';
            if (location.pathname === '/catalog') {
                searchText = params.query ? params.query.toString() : '';
            }
            this.setState({
                searchText
            });
        });
    }

    onChangeSearchText({ value }) {
        this.setState({
            searchText: value
        });
        this.props.history.push({
            pathname: `/catalog`,
            search: value ? `?query=${encodeURIComponent(value)}` : null,
            state: {
                searchText: value
            }
        });
    }

    public render() {
        return (
            <div className="wrapper page-background">
                <Preloader />
                <Header searchText={this.state.searchText} onChangeSearchText={this.onChangeSearchText} />
                <Router searchText={this.state.searchText} />
            </div>
        );
    }
}

export default withRouter(Application);
