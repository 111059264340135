import { IManufacture } from '../../models';
import * as React from 'react';

export class ManufactureComponent extends React.Component<IManufacture, any> {
    public render() {
        return (
            <li className="list__item container-style text_dark text-17 text-13-mobile">
                <span className="list__img-container">
                    <img src={this.props.url} alt={this.props.name} />
                </span>
                <span className="list__text">
                    <span className="text_medium">{this.props.name}</span>
                    {this.props.description}
                </span>
            </li>
        );
    }
}
