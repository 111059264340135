import * as React from 'react';
import '../styles/pages/contacts.scss';

class Contacts extends React.Component {
    render() {
        return (
            <div className="contacts-page page-background">
                <div className="container">
                    <h1 className="text-title-mobile">Контакты</h1>
                </div>
                <div className="background-block">
                    <div className="container">
                        <div className="title-container">
                            <h2 className="text-title text-36 text_white text_medium">ООО «Электроватт»</h2>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="container-style contacts__cols">
                        <ul className="contacts__list">
                            <li className="contacts__item text-15 text_dark text_medium text-mobile">ООО «Электроватт»</li>
                            <li className="contacts__item contacts__item_location">
                                <span className="contacts__title text_gray text-13">Адрес</span>
                                <a
                                    className="contacts__link text_dark text-17 text-15-mobile"
                                    target="_blank"
                                    href="https://goo.gl/maps/FkUBsow6DtT2"
                                >
                                    197341, Санкт-Петербург, ул. Серебристый бульвар, 29-2
                                </a>
                            </li>
                            <li className="contacts__item contacts__item_phone">
                                <span className="contacts__title text_gray text-13">
                                    Многоканальный телефон (с 09:00 до 18:00 по будням)
                                </span>
                                <a className="contacts__link text_dark text-17 text-15-mobile" href="tel:+78123631278">
                                    +7 (812) 363-12-78
                                </a>
                            </li>
                            <li className="contacts__item contacts__item_mail">
                                <span className="contacts__title text_gray text-13">E-mail для заказов</span>
                                <a className="contacts__link text_dark text-17 text-15-mobile" href="mailto:zakaz@electrovatt.ru">
                                    zakaz@electrovatt.ru
                                </a>
                            </li>
                            <li className="contacts__item contacts__item_phone">
                                <span className="contacts__title text_gray text-13">
                                    Департамент снабжения (с 09:00 до 18:00 по будням)
                                </span>
                                <a className="contacts__link text_dark text-17 text-15-mobile" href="tel:+78123631278">
                                    +7 (812) 363-12-78 <span className="text_gray text-13">доб.</span> 205
                                </a>
                            </li>
                            <li className="contacts__item contacts__item_mail">
                                <span className="contacts__title text_gray text-13">E-mail департамента снабжения</span>
                                <a className="contacts__link text_dark text-17 text-15-mobile" href="mailto:msv@electrovatt.ru">
                                    msv@electrovatt.ru
                                </a>
                            </li>
                            <li className="contacts__item contacts__item_phone">
                                <span className="contacts__title text_gray text-13">Отдел продаж (с 09:00 до 18:00 по будням)</span>
                                <a className="contacts__link text_dark text-17 text-15-mobile" href="tel:+78123631278">
                                    +7 (812) 363-12-78 <span className="text_gray text-13">доб.</span> 201
                                </a>
                            </li>
                            <li className="contacts__item contacts__item_mail">
                                <span className="contacts__title text_gray text-13">E-mail отдела продаж</span>
                                <a className="contacts__link text_dark text-17 text-15-mobile" href="mailto:zakaz@electrovatt.ru">
                                    zakaz@electrovatt.ru
                                </a>
                            </li>
                            <li className="contacts__item contacts__item_phone">
                                <span className="contacts__title text_gray text-13">Архив (с 09:00 до 18:00 по будням)</span>
                                <a className="contacts__link text_dark text-17 text-15-mobile" href="tel:+78123631278">
                                    +7 (812) 363-12-78 <span className="text_gray text-13">доб.</span> 217
                                </a>
                            </li>
                            <li className="contacts__item contacts__item_phone contacts__item_marker">
                                <span className="contacts__title text_gray text-13">
                                    «Горячая линия» ООО «Электроватт» по противодействию коррупции и хищениям
                                </span>
                                <a className="contacts__link text_dark text-17 text-15-mobile" href="tel:+79219340937">
                                    +7 (921) 395-94-04
                                </a>
                            </li>
                        </ul>
                        <ul className="contacts__list">
                            <li className="contacts__item text_dark text_medium text-17 text-15-mobile">
                                Список крупных складов на территории РФ
                            </li>
                            <li className="contacts__item contacts__item_location">
                                <span className="contacts__title text_gray text-13">Склад 1 (с 09:00 до 18:00 по будням)</span>
                                <a
                                    className="contacts__link text_dark text-17 text-15-mobile"
                                    target="_blank"
                                    href="https://goo.gl/maps/esL3tNuMuKP2"
                                >
                                    Санкт-Петербург, Горское шоссе, 6
                                </a>
                            </li>
                            <li className="contacts__item contacts__item_location">
                                <span className="contacts__title text_gray text-13">Склад 2 (24 часа, Спецпропуск за сутки)</span>
                                <a
                                    className="contacts__link text_dark text-17 text-15-mobile"
                                    target="_blank"
                                    href="https://goo.gl/maps/soofPgmEJru"
                                >
                                    Москва, Шереметьевское шоссе, 2А
                                </a>
                            </li>
                            <li className="contacts__item contacts__item_location">
                                <span className="contacts__title text_gray text-13">
                                    Склад 3 (с 09:00 до 18:00 по будням, Спецпропуск за сутки)
                                </span>
                                <a
                                    className="contacts__link text_dark text-17 text-15-mobile"
                                    target="_blank"
                                    href="https://goo.gl/maps/UTDWgtSfiSC2"
                                >
                                    Адлер, ул. Мира, 40
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default Contacts;
