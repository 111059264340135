import * as React from 'react';
import { IProduct } from '../../models';

class Product extends React.Component<IProduct, {}> {
    render() {
        let classNamePromoution = 'catalog-list__col4';
        if (this.props.promoution && this.props.promoution === 1) {
            classNamePromoution = 'catalog-list__col4';
            classNamePromoution += ' catalog-list__promoutions';
        }
        return (
            <li className="catalog-list__product text_dark text-15 text-13-mobile">
                <span className="catalog-list__col1 text_medium text-15-mobile"> {this.props.name} </span>
                <span className="catalog-list__col2">
                    <span className="catalog-list__mobile text_gray">Завод-изготовитель:</span> {this.props.manufacture}
                </span>
                <span className="catalog-list__col3">
                    {' '}
                    {this.props.quantity} <span className="text_gray">{this.props.unit}</span>
                </span>
                <span className={classNamePromoution}>
                    <span className="catalog-list__mobile text_gray catalog-list__price_mobile">Цена за 1 ед. с НДС (самовывоз): </span>
                    <span className="catalog-list__price">
                        {this.props.price} <span className="text_gray">₽</span>
                    </span>
                </span>
            </li>
        );
    }
}

export default Product;
