import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import '../../styles/pages/admin.scss';
import { api } from '../../models';
import FileUploader from './FileUploader';

interface IState {
    tokenValid: boolean;
}

class Admin extends React.Component<RouteComponentProps, IState> {
    constructor(props: RouteComponentProps) {
        super(props);

        this.state = {
            tokenValid: false
        };
    }

    async componentDidMount() {
        const { location, history } = this.props;
        let authorizeUrl = '/';

        try {
            const response = await window.fetch(api.tokenCheck);
            const tokenResponse = await response.json();
            const tokenValid = (tokenResponse && tokenResponse.valid) || false;

            authorizeUrl = tokenResponse && tokenResponse.authorizeUrl;

            this.setState({ tokenValid });

            if (!tokenValid) {
                window.location.href = authorizeUrl;
            }

            // очистка url от code
            const isCodeQuery = location.search.includes('code');

            if (isCodeQuery) {
                history.push('/admin');
            }
        } catch (_) {
            window.location.href = authorizeUrl;
        }
    }

    render() {
        const { tokenValid } = this.state;

        if (!tokenValid) {
            return <div>Redirect...</div>;
        }

        return (
            <div className="wrapper">
                <FileUploader />
            </div>
        );
    }
}

export default Admin;
