import * as React from 'react';
import '../styles/pages/about.scss';

class About extends React.Component {
    render() {
        return (
            <div className="about page-background">
                <div className="container">
                    <h1 className="text-title-mobile">О компании</h1>
                </div>
                <div className="background-block">
                    <div className="container">
                        <div className="title-container">
                            <h2 className="text-title text-36 text_white text_medium">ООО «Электроватт»</h2>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="container-style text_dark text-17 text-15-mobile">
                        <p className="text-top-0">
                            <span className="text_medium">Компания ООО «Электроватт»</span> профессиональный дистрибьютор и дилер более
                            сотни заводов электротехнической продукции.
                        </p>
                        <p>
                            Мы работаем на рынке Санкт-Петербурга, Ленинградской области и России уже более 10 лет. Мы также являемся
                            надежными представителями наших зарубежных партнеров таких как Siemens, ABB, Schneider Electric, Rittal.
                        </p>
                        <p>
                            Мы предлагаем широкий спектр низковольтной и высоковольтной электротехнической продукции, кабельно-проводниковой
                            продукции, промышленное и щитовое оборудование, в том числе расчет производства щитового оборудования и
                            подстанций: ЩО-70, ГРЩ, НКУ, КРУ, КСО, КН, и т.д.
                        </p>
                        <p>Наши преимущества:</p>
                        <ul className="text-list">
                            <li>Лучшая цена</li>
                            <li>Огромный товарный запас, более 980 тысяч наименований товара.</li>
                            <li>Отсрочка платежа от 15 до 90 дней (на продукцию, поддерживаемую производителем на складе отгрузки)</li>
                            <li>Сжатые сроки поставки</li>
                            <li>Бесплатная доставка (личный автопарк, авто, суда, авиа)</li>
                            <li>Квалифицированная техническая поддержка</li>
                        </ul>

                        <p>Наши клиенты:</p>
                        <ul className="text-bottom-0 text-list">
                            <li>ПАО Газпром</li>
                            <li>ПАО «Газпром нефть»</li>
                            <li>АО «Газпромбанк»</li>
                            <li>Государственная корпорация по атомной энергии «Росатом»</li>
                            <li>АО «Концерн Росэнергоатом»</li>
                            <li>Министерство Энергетики РФ</li>
                            <li>Министерство Обороны РФ</li>
                            <li>ПАО «НК «РОСНЕФТЬ»</li>
                            <li>ПАО «ЛУКОЙЛ»</li>
                            <li>ПАО «Интер РАО»</li>
                            <li>ОАО «РЖД»</li>
                            <li>ПАО «СБЕРБАНК России»</li>
                            <li>Горно-обогатительные комплексы, металлургические и деревообрабатывающие комбинаты</li>
                        </ul>
                        <p>Мы поставляем продукцию:</p>
                        <ul className="text-list">
                            <li>
                                на российские АЭС: Балаковская, Белоярская, Билибинская, Калининская, Кольская, Курская, Ленинградская,
                                Нововоронежская, Ростовская, Смоленская, плавучая АЭС «Академик Ломоносов»
                            </li>
                            <li>
                                на зарубежные АЭС: Куданкулам (Индия), Руппур (Бангладеш), Ханхикиви (Финляндия), Эль-Дабаа (Египет), Бушер
                                (Иран), Аккую (Турция)
                            </li>
                            <li>на космодромы «Восточный» и «Плесецк»</li>
                            <li>Керченский мост</li>
                            <li>Северный поток 1 и 2 </li>
                            <li>Южный поток </li>
                            <li>Сила Сибири</li>
                            <li>ПАО «Ленэнерго»</li>
                            <li>ПАО «Аэрофлот»</li>
                            <li>Гос. корпорация «Роскосмос»</li>
                            <li>другие стратегические объекты как на территории РФ, так и на территории ближнего и дальнего зарубежья.</li>
                        </ul>
                        <p>Мы рады приветствовать Вас на нашем сайте и готовы ответить на все Ваши вопросы!</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default About;
