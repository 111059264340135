import * as React from 'react';
import Dropzone from 'react-dropzone';

import FileView from './FileView';
import { api } from '../../models';
import '../../styles/pages/admin.scss';
import SendButton from './SendButton';
import ErrorView from './ErrorView';
import Instruction from './Instruction';

type State = {
    file: File | undefined;
    loading: boolean;
    errors: null | [];
    success: boolean;
};

const CSV_EXTENTION = 'csv';

class FileUploader extends React.Component<{}, State> {
    constructor(props) {
        super(props);

        this.state = {
            file: undefined,
            loading: false,
            errors: null,
            success: false
        };
    }

    onDrop = (files: File[]) => {
        const file = files[0];

        if (this.handleValidation(file)) {
            this.setState({ file, errors: null, success: false });
        } else {
            alert(`Файл ${file.name} имеет неправильное расширение. Загрузить можно только файл "csv"`);
        }
    };

    handleValidation = (file: File) => {
        const { name } = file;
        const extensionFile = this.getExtensionByFileName(name);

        return extensionFile === CSV_EXTENTION;
    };

    getExtensionByFileName = (fileName: string): string => fileName.split('.').pop();

    handleSendFile = async () => {
        try {
            this.setState({ loading: true });
            const formData = new FormData();

            formData.append('file', this.state.file);

            const response = await window.fetch(api.writeCatalog, {
                method: 'POST',
                body: formData
            });

            const result = await response.json();

            this.setState({ errors: result.errors, success: !result.errors });
        } catch (e) {
            this.setState({ errors: null, success: false });
        } finally {
            this.setState({ loading: false });
        }
    };

    render() {
        const { file, loading, errors, success } = this.state;

        return (
            <React.Fragment>
                <Instruction />
                <div className="dropzone-container">
                    <Dropzone onDrop={this.onDrop}>
                        {({ getRootProps, getInputProps }) => (
                            <section className="">
                                <div {...getRootProps({ className: 'dropzone' })}>
                                    <input {...getInputProps()} />
                                    <div className="dropzone-wrapper">Выберите файл для загрузки</div>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                </div>
                <FileView file={file} />
                <SendButton onClick={this.handleSendFile} visible={!!file && !loading} />
                <ErrorView errors={errors} />
                {success && <div className="success">Файл успешно загружен!</div>}
            </React.Fragment>
        );
    }
}

export default FileUploader;
