import * as React from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/common/navigation.scss';

interface INavigationState {
    activeMenu: boolean;
}

class Navigation extends React.Component<any, INavigationState> {
    constructor(props) {
        super(props);
        this.state = {
            activeMenu: false
        };
    }

    openMenu() {
        this.setState({
            activeMenu: true
        });
    }

    closeMenu() {
        this.setState({
            activeMenu: false
        });
    }

    overlayHtml() {
        if (this.state.activeMenu) {
            return <div className="active-dropdown" onClick={this.closeMenu} />;
        }
    }

    render() {
        return (
            <div className="navigation-container">
                <button className="navigation-button" onClick={this.openMenu}>
                    <span className="navigation-button__element" />
                    <span className="navigation-button__element" />
                    <span className="navigation-button__element" />
                </button>
                {this.overlayHtml()}
                <ul className="navigation text_medium text_dark text-14">
                    <li className="navigation__item">
                        <NavLink onClick={this.closeMenu} exact className="navigation__link" activeClassName="active" to="/">
                            О компании
                        </NavLink>
                    </li>
                    <li className="navigation__item">
                        <NavLink onClick={this.closeMenu} className="navigation__link" activeClassName="active" to="/manufactures">
                            Производители
                        </NavLink>
                    </li>
                    <li className="navigation__item">
                        <NavLink onClick={this.closeMenu} className="navigation__link" activeClassName="active" to="/catalog">
                            Каталог товаров
                        </NavLink>
                    </li>
                    {/* Временно отключаем из-за неработоспособности
                    <li className="navigation__item">
                        <NavLink
                            onClick={this.closeMenu}
                            className="navigation__link navigation__link_icon"
                            activeClassName="active"
                            to="/promoutions"
                        >
                            Акции
                        </NavLink>
                    </li>
                    <li className="navigation__item">
                        <NavLink onClick={this.closeMenu} className="navigation__link" activeClassName="active" to="/supply">
                            Галерея поставок
                        </NavLink>
                    </li>
                    */}
                    <li className="navigation__item">
                        <NavLink onClick={this.closeMenu} className="navigation__link" activeClassName="active" to="/contacts">
                            Контакты
                        </NavLink>
                    </li>
                </ul>
            </div>
        );
    }
}

export default Navigation;
