import * as React from 'react';
import '../../styles/pages/catalog.scss';
import Product from './Product';
import Pagination from './Pagination';
import * as _ from 'lodash';
import { api, ICatalog, ISearch } from '../../models';

class Catalog extends React.Component<ISearch, ICatalog> {
    private debouncedFetchCatalog;

    constructor(props) {
        super(props);
        this.state = {
            products: [],
            offset: 0,
            limit: 15,
            total: 0
        };
        this.onChangePage = this.onChangePage.bind(this);
        this.debouncedFetchCatalog = _.debounce(this.fetchCatalog, 1000);
    }

    async fetchCatalog({ query, offset, limit }) {
        const response = await window.fetch(api.catalog, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                query,
                offset,
                limit
            })
        });
        const catalog = await response.json();
        this.setState({
            products: catalog.products,
            offset: catalog.offset,
            total: catalog.total
        });
    }

    async onChangePage({ page, offset }) {
        this.setState({
            offset
        });

        await this.fetchCatalog({
            query: this.props.searchText,
            offset,
            limit: this.state.limit
        });
    }

    public async componentWillMount() {
        await this.fetchCatalog({
            query: this.props.searchText,
            offset: this.state.offset,
            limit: this.state.limit
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.searchText !== prevProps.searchText) {
            this.debouncedFetchCatalog({
                query: this.props.searchText,
                offset: this.state.offset,
                limit: this.state.limit
            });
        }
    }

    render() {
        const productsList = this.state.products.map((product, ind) => {
            return <Product key={ind} {...product} />;
        });
        return (
            <div className="сatalog page-background">
                <div className="container">
                    <h1 className="text-title-mobile">Каталог товаров</h1>
                </div>
                <div className="container catalog__container">
                    <ul className="container-style catalog-list">
                        <li className="catalog-list__header text_gray text-13">
                            <span className="catalog-list__col1">Наименование</span>
                            <span className="catalog-list__col2">Завод-изготовитель</span>
                            <span className="catalog-list__col3">Кол-во</span>
                            <span className="catalog-list__col4">Цена за 1 ед. с НДС (самовывоз)</span>
                        </li>
                        <li className="catalog-list__main">
                            <ul className="catalog-list__products">{productsList}</ul>
                        </li>
                        <li className="catalog-list__footer">
                            <Pagination
                                offset={this.state.offset}
                                limit={this.state.limit}
                                total={this.state.total}
                                onChangePage={this.onChangePage}
                            />
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default Catalog;
