import * as React from 'react';
import '../../styles/pages/manufactures.scss';
import { api, IManufacture } from '../../models';
import { ManufactureComponent } from './Manufacture';

interface IManufacturesProps {
    manufactures: IManufacture[];
}

export default class Manufactures extends React.Component<IManufacturesProps, IManufacturesProps> {
    render() {
        let manufactureComponents = [];
        if (this.state && this.state.manufactures) {
            manufactureComponents = this.state.manufactures.map((manufacture, index) => {
                return <ManufactureComponent {...manufacture} key={index} />;
            });
        }
        return (
            <div className="manufactures page-background">
                <div className="container">
                    <h1 className="text-title-mobile">Производители</h1>
                </div>
                <div className="container">
                    <ul className="list list-with-img">{manufactureComponents}</ul>
                </div>
            </div>
        );
    }

    public async componentWillMount() {
        const response = await window.fetch(api.manufactures);
        const manufactures = await response.json();
        this.setState(manufactures);
    }
}
