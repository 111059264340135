import * as React from 'react';
import '../styles/common/search.scss';
import { ISearchProp } from '../models';

class Search extends React.Component<ISearchProp, {}> {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.onChangeSearchText({
            value: event.target.value
        });
    }

    render() {
        return (
            <div className="search">
                <input
                    className="search__input text-15 text_dark text_normal"
                    type="text"
                    placeholder="Поиск товара"
                    value={this.props.searchText}
                    onChange={this.handleChange}
                />
                <button className="search__button" />
            </div>
        );
    }
}

export default Search;
