import * as React from 'react';
import { IPaginationProp, IPaginationState } from '../../models';

export default class Pagination extends React.Component<IPaginationProp, IPaginationState> {
    constructor(props) {
        super(props);
        this.state = {
            pager: {
                totalItems: 0,
                currentPage: 0,
                pageSize: 0,
                totalPages: 0,
                startPage: 0,
                endPage: 0,
                startIndex: 0,
                endIndex: 0,
                pages: []
            }
        };
    }

    componentWillMount() {
        this.setPage(1);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.total !== prevProps.total) {
            const pager = this.getPager({
                totalItems: this.props.total,
                currentPage: 1,
                pageSize: this.props.limit
            });
            this.setState({ pager });
            this.setPage(1);
        }
    }

    setPage(page) {
        let pager = this.state.pager;
        if (page < 1 || page > pager.totalPages) {
            return;
        }

        pager = this.getPager({
            totalItems: this.props.total,
            currentPage: page,
            pageSize: this.props.limit
        });
        this.setState({ pager });

        let offset = pager.startIndex;
        if (offset === -1) {
            offset = 0;
        }

        this.props.onChangePage({
            page,
            offset
        });
    }

    getPager({ totalItems, currentPage, pageSize }) {
        currentPage = currentPage || 1;

        pageSize = pageSize || 5;

        const totalPages = Math.ceil(totalItems / pageSize);

        let startPage: number;
        let endPage: number;
        if (totalPages <= 10) {
            startPage = 1;
            endPage = totalPages;
        } else {
            if (currentPage <= 6) {
                startPage = 1;
                endPage = 10;
            } else if (currentPage + 4 >= totalPages) {
                startPage = totalPages - 9;
                endPage = totalPages;
            } else {
                startPage = currentPage - 5;
                endPage = currentPage + 4;
            }
        }

        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        const pages = [...Array(endPage + 1 - startPage).keys()].map(i => startPage + i);

        return {
            totalItems,
            currentPage,
            pageSize,
            totalPages,
            startPage,
            endPage,
            startIndex,
            endIndex,
            pages
        };
    }

    render() {
        const pager = this.state.pager;
        if (!pager.pages || pager.pages.length <= 1) {
            return null;
        }
        return (
            <span className="pagination">
                <button className="pagination__button pagination__button_left" onClick={() => this.setPage(pager.currentPage - 1)} />
                {pager.pages.map((page, index) => (
                    <button
                        key={index}
                        className={pager.currentPage === page ? 'pagination__button pagination__button_active' : 'pagination__button'}
                        onClick={() => this.setPage(page)}
                    >
                        {page}
                    </button>
                ))}
                <button className="pagination__button pagination__button_right" onClick={() => this.setPage(pager.currentPage + 1)} />
            </span>
        );
    }
}
